import React from 'react';
import ItemPreviewValue from './value';
import '../styles/entry.scss';

/**
 * Display one entry (key, value combination) of a feed item.
 * @param {*} param0
 * @returns
 */
const ItemPreviewEntry = ({ entryValue, entryKey, displayTypes, markAsOld, markAsNew }) => {
    return (
        <div className="feed-management-item-preview-entry">
            <div className="feed-management-item-preview-entry__label">
                <div className="feed-management-item-preview-entry__label__formatted-key">{entryKey}</div>
            </div>
            <div className="feed-management-item-preview-entry__value">
                <ItemPreviewValue value={entryValue} entryKey={entryKey} displayTypes={displayTypes} markAsOld={markAsOld} markAsNew={markAsNew} />
            </div>
        </div>
    );
};

export default ItemPreviewEntry;
