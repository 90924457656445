import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import OutputCardBodyLabel from './output-card-body-label';
import Icon from 'components/ui-components-v2/Icon';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { FeedRequest } from 'components/data/FeedManagementHelpers';
import '../styles/output-card-body-download.scss';
import Translation from 'components/data/Translation';

/**
 * Copy the url to the users clipboard.
 * @param {string} url
 */
const copy = (url) => {
    navigator.clipboard.writeText(url);
    SnackbarUtils.success('Copied!', 2000);
};

/**
 * Display the url part of an outputcard of type 'download'.
 * @param {*} param0
 * @returns
 */
const OutputCardBodyDownload = ({ output, dataSet }) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        FeedRequest.get(`dataset/${dataSet._id}/output/${output.outputId}/token`)
            .then((data) => {
                setToken(data.data.token);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const stream = `${process.env.FEED_MANAGER}streams/output/${token}`;
    return (
        <div className="feed-management-output-card-body-download">
            <OutputCardBodyLabel label={Translation.get('outputs.outputBodies.download', 'feed-management')} />
            <div className="feed-management-output-card-body-download__link">
                {token ? (
                    <React.Fragment>
                        <a href={`${process.env.FEED_MANAGER}streams/output/${token}`} target="_blank">
                            {stream}
                        </a>
                        <IconButton onClick={() => copy(stream)}>
                            <Icon fontSize="small">content_copy</Icon>
                        </IconButton>
                    </React.Fragment>
                ) : (
                    <CircularProgress />
                )}
            </div>
        </div>
    );
};

export default OutputCardBodyDownload;
