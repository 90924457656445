import React from 'react';
import AlertBox from 'components/ui-components/AlertBox/components';
import '../styles/output-card-error.scss';

const OutputCardError = ({ output }) => {
    if (output.latestOutputUpdate && output.latestOutputUpdate.errorMessage) {
        return (
            <AlertBox type="error" hideClose className="output-card-error">
                {output.latestOutputUpdate.errorMessage}
            </AlertBox>
        );
    } else {
        return null;
    }
};

export default OutputCardError;
