import React from 'react';
import OutputCardHeader from './output-card-header';
import OutputCardBody from './output-card-body';
import OutputCardFooter from './output-card-footer';
import '../styles/main.scss';

/**
 * Display an output card for the output tab of a data set.
 * @param {*} param0
 * @returns
 */
const OutputCard = ({ dataSet, output }) => {
    return (
        <div className="feed-management-output-card">
            <OutputCardHeader output={output} dataSet={dataSet} />
            <OutputCardBody output={output} dataSet={dataSet} />
            <OutputCardFooter output={output} dataSet={dataSet} />
        </div>
    );
};

export default OutputCard;
