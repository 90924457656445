import React from 'react';
import OutputCardError from './output-card-error';
import '../styles/output-card-footer.scss';
import Icon from 'components/ui-components-v2/Icon';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import Translation from 'components/data/Translation';

/**
 * Dsiplay the latest feed update and autopublish action.
 * @param {*} param0
 * @returns
 */
const OutputCardFooterGetLast = ({ dataSet }) => {
    if (dataSet.metadata) {
        if (dataSet.latestUpdate) {
            return (
                <React.Fragment>
                    <div className="feed-management-output-card-footer__header">{Translation.get('outputs.latestUpdate', 'feed-management')}</div>
                    <div className="feed-management-output-card-footer__date">{FeedHelpers.formatLatestUpdateDate(dataSet.latestUpdate)}</div>
                </React.Fragment>
            );
        } else if (dataSet.metadata && dataSet.metadata.lastManualChange) {
            return (
                <React.Fragment>
                    <div className="feed-management-output-card-footer__header">{Translation.get('outputs.latestManualUpdate', 'feed-management')}</div>
                    <div className="feed-management-output-card-footer__date">
                        {dataSet.metadata && FeedHelpers.formatLatestUpdateDate(dataSet.metadata.lastManualChange)}
                    </div>
                </React.Fragment>
            );
        } else return null;
    } else return null;
};

/**
 * Display the foor
 * @param {*} param0
 * @returns
 */
const OutputCardFooter = ({ output, dataSet }) => {
    return (
        <React.Fragment>
            <OutputCardError output={output} />
            <div className="feed-management-output-card-footer">
                <Icon>update</Icon>
                <div className="feed-management-output-card-footer__copy">
                    <OutputCardFooterGetLast dataSet={dataSet} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default OutputCardFooter;
