import React, { useState } from 'react';
import '../styles/output-card-header.scss';
import IconButton from '@mui/material/IconButton';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import Icon from 'components/ui-components-v2/Icon';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import OutputForm from '../../OutputForm';

/**
 * Display the header of an output card.
 * @param {*} param0
 * @returns
 */
const OutputCardHeader = ({ output, dataSet }) => {
    const [waiting, setWaiting] = useState(false);
    const [showRemoveOutput, setShowRemoveOutput] = useState(false);

    /**
     * Remove an output from a feed
     * @param {object} output the output to remove
     */
    const removeOutput = (output) => {
        setWaiting(true);
        FeedRequest.delete(`/dataset/${dataSet._id}/output/${output.outputId}`)
            .then(() => {
                SnackbarUtils.success(Translation.get('outputs.outputRemoved', 'feed-management'));
                FeedHelpers.reloadDataSet(dataSet._id);
                setShowRemoveOutput(false);
                setWaiting(true);
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('outputs.addOutputFailed', 'feed-management'));
                console.error(error);
                setShowRemoveOutput(false);
                setWaiting(true);
            });
    };

    return (
        <React.Fragment>
            <div className="feed-management-output-card-header">
                <div className="feed-management-output-card-header__content">
                    <div className="feed-management-output-card-header__copy">
                        <div className="feed-management-output-card-header__title">{output.name}</div>
                        <div className="feed-management-output-card-header__sub">
                            <span>{output.function}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <OutputForm output={output} dataSet={dataSet}>
                        <IconButton>
                            <Icon>create</Icon>
                        </IconButton>
                    </OutputForm>
                    <IconButton onClick={() => setShowRemoveOutput(!showRemoveOutput)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </div>
            </div>
            {showRemoveOutput && (
                <ConfirmDialog
                    title={Translation.get('outputs.confirmDialogTitle', 'feed-management')}
                    description={Translation.get('outputs.confirmDialogDescription', 'feed-management', {
                        outputName: output.name
                    })}
                    onConfirm={() => removeOutput(output)}
                    open={true}
                    waiting={waiting}
                    onClose={() => setShowRemoveOutput(false)}
                />
            )}
        </React.Fragment>
    );
};

export default OutputCardHeader;
