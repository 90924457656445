import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import OutputCardBodyLabel from './output-card-body-label';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import LWFiles from 'components/data/Files';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';

import '../styles/output-card-body-cloud.scss';
import Translation from 'components/data/Translation';

/**
 * Copy the url to the users clipboard.
 * @param {string} url
 */
const copy = (url) => {
    navigator.clipboard.writeText(url);
    SnackbarUtils.success('Copied!', 2000);
};

/**
 * Display the url part of an outputcard of type 'cloud'.
 * @param {*} param0
 * @returns
 */
const OutputCardBodyCloud = ({ output }) => {
    const [downloading, setDownloading] = useState(false);

    const downloadFile = (url, name) => {
        setDownloading(true);
        LWFiles.downloadFileFromUrl(url, name, setDownloading(false));
    };

    return (
        <div className="feed-management-output-card-body-cloud">
            <OutputCardBodyLabel label={Translation.get('outputs.outputBodies.cloud', 'feed-management')} />

            <div className="feed-management-output-card-body-cloud__link">
                <a href={output.url} target="_blank">
                    {output.url}
                </a>
                <IconButton onClick={() => copy(output.url)}>
                    <Icon fontSize="small">content_copy</Icon>
                </IconButton>
            </div>

            <Button
                onClick={() => downloadFile(output.url, `${output.name}.${output.format}`)}
                variant="contained"
                disabled={downloading}
                className="feed-management-output-card-body-cloud__button"
                startIcon={<Icon>download</Icon>}>
                {Translation.get('actions.download', 'common')}
            </Button>
        </div>
    );
};

export default OutputCardBodyCloud;
