import React from 'react';
import IconButton from '@mui/material/IconButton';
import OutputCardBodyLabel from './output-card-body-label';
import Button from 'components/ui-components-v2/Button';
import Icon from 'components/ui-components-v2/Icon';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import '../styles/output-card-body-spreadsheet.scss';
import Translation from 'components/data/Translation';

/**
 * Copy the url to the users clipboard.
 * @param {string} url
 */
const copy = (url) => {
    navigator.clipboard.writeText(url);
    SnackbarUtils.success('Copied!', 2000);
};

/**
 * Display the url part of an outputcard of type 'Spreadsheet'.
 * @param {*} param0
 * @returns
 */
const OutputCardBodySpreadsheet = ({ output }) => {
    return (
        <div className="feed-management-output-card-body-spreadsheet">
            <OutputCardBodyLabel label={Translation.get('outputs.outputBodies.spreadsheet', 'feed-management')} />
            <div className="feed-management-output-card-body-spreadsheet__link">
                <a href={output.spreadsheetUrl} target="_blank">
                    {output.url}
                </a>
                <IconButton onClick={() => copy(output.url)}>
                    <Icon fontSize="small">content_copy</Icon>
                </IconButton>
            </div>

            <Button
                target="_blank"
                component="a"
                href={output.url}
                variant="contained"
                color="primary"
                className="feed-management-output-card-body-spreadsheet__button"
                startIcon={<Icon>open_in_browser</Icon>}>
                {Translation.get('actions.open', 'common')}
            </Button>
        </div>
    );
};

export default OutputCardBodySpreadsheet;
