import React, { useState, useEffect, useRef } from 'react';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import FormFlow from 'components/ui-base/FormFlow';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import '../styles/main.scss';
import Translation from 'components/data/Translation';

const getOutputColumns = (dataSet) => {
    const columnNames = [];
    if (dataSet && dataSet.structure) {
        const structure = dataSet.structure;

        Object.keys(structure).forEach((key) => {
            columnNames.push({
                value: key,
                label: key
            });
        });
    } else if (dataSet && dataSet.customData && dataSet.customData.dataSetStructure && dataSet.customData.dataSetStructure.length) {
        const structure = dataSet.customData.dataSetStructure;

        structure.forEach((item) => {
            columnNames.push({
                value: item.key,
                label: item.key
            });
        });
    }
    return columnNames;
};

/**
 *
 * @param {array} setup
 * @param {*} outputColumns
 * @returns
 */
const getFormSetup = (setup, outputColumns) => {
    let formSetup;
    if (setup && Array.isArray(setup)) {
        formSetup = setup;
    } else {
        formSetup = [
            {
                items: [
                    {
                        type: 'text',
                        model: 'name',
                        title: Translation.get('editor.outputForm.name', 'feed-management'),
                        validators: 'required'
                    },
                    {
                        type: 'radioGroup',
                        model: 'type',
                        title: Translation.get('editor.outputForm.type', 'feed-management'),
                        options: FeedHelpers.outputTypes,
                        validators: 'required'
                    },
                    {
                        type: 'text',
                        model: 'url',
                        title: Translation.get('editor.outputForm.spreadsheet', 'feed-management'),
                        description: Translation.get('editor.outputForm.spreadsheetDescription', 'feed-management'),
                        condition: "data.type=='spreadsheet'"
                    },
                    {
                        type: 'text',
                        model: 'sheetName',
                        title: Translation.get('editor.outputForm.sheetName', 'feed-management'),
                        condition: "data.type=='spreadsheet'"
                    },
                    {
                        type: 'select',
                        model: 'format',
                        title: Translation.get('editor.outputForm.format', 'feed-management'),
                        options: {
                            json: 'JSON',
                            csv: 'CSV',
                            xml: 'XML'
                        },
                        condition: "data.type!='spreadsheet'",
                        validators: 'required'
                    },
                    {
                        type: 'autocomplete',
                        model: 'fields',
                        title: Translation.get('editor.outputForm.fields', 'feed-management'),
                        description: Translation.get('editor.outputForm.fieldsDescription', 'feed-management'),
                        options: outputColumns
                    },
                    {
                        type: 'switch',
                        model: 'keepMetadata',
                        title: 'Keep metadata'
                    },
                    {
                        type: 'fieldMap',
                        model: 'filters',
                        title: Translation.get('editor.outputForm.filters', 'feed-management'),
                        hasComparison: true,
                        canAdd: true,
                        fieldLabel: Translation.get('editor.outputForm.fieldLabel', 'feed-management'),
                        fieldOptions: outputColumns,
                        valueLabel: Translation.get('editor.outputForm.fieldValue', 'feed-management')
                    },
                    {
                        type: 'fieldMap',
                        model: 'sortCriterias',
                        title: Translation.get('labels.sorting', 'common'),
                        canAdd: true,
                        fieldLabel: Translation.get('editor.outputForm.fieldLabel', 'feed-management'),
                        fieldOptions: outputColumns,
                        valueLabel: Translation.get('editor.outputForm.fieldValue', 'feed-management'),
                        valueOptions: [
                            { value: '', label: Translation.get('filter.none', 'common') },
                            { value: '0', label: Translation.get('labels.desc', 'common') },
                            { value: '1', label: Translation.get('labels.asc', 'common') }
                        ]
                    },
                    {
                        type: 'text',
                        model: 'limit',
                        title: Translation.get('outputs.limit', 'feed-management'),
                        fieldType: 'number'
                    }
                ]
            }
        ];
    }
    return formSetup;
};

/** empty output */
const newData = {
    type: 'download',
    format: 'json'
};

/** Some data tweaks to make form work
 * @param {object} feed - original feed;
 */
const transformFormData = (output) => {
    const transformedData = { ...output };

    if (output.fields) {
        const newFields = [];

        output.fields.forEach((field) => {
            newFields.push({
                value: field,
                label: field
            });
        });

        transformedData.fields = newFields;
    } else {
        transformedData.fields = [];
    }

    if (output.sortCriterias) {
        transformedData.sortCriterias = output.sortCriterias.map((sortCriteria) => {
            return {
                field: sortCriteria.fieldName,
                value: sortCriteria.order.toString()
            };
        });
    }

    return transformedData;
};

const getTitle = (output) => {
    return output.outputId ? Translation.get('editor.outputForm.edit', 'feed-management') : Translation.get('editor.outputForm.add', 'feed-management');
};

/** Form in a dialog to add or edit output */
const OutputForm = ({ dataSet, setup = undefined, output = {}, children, callback = () => {} }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [outputColumns, setOutputColumns] = useState(getOutputColumns(dataSet));
    const [formSetup, setFormSetup] = useState(getFormSetup(setup, outputColumns));
    const [formData, setFormData] = useState(transformFormData(output.outputId ? output : newData));
    const [title, setTitle] = useState(getTitle(output));

    const referenceOutputId = useRef(output.outputId);

    useEffect(() => {
        if (showDialog && dataSet) {
            const newOutputColumns = getOutputColumns(dataSet);
            setOutputColumns(newOutputColumns);
            setFormSetup(getFormSetup(setup, newOutputColumns));
        }
    }, [showDialog, dataSet]);

    useEffect(() => {
        if (output.outputId !== referenceOutputId.current) {
            setFormData(transformFormData(output.outputId ? output : newData));
            setTitle(getTitle(output));
        }
    }, [output]);

    const transformPostData = (data) => {
        const transformedData = { ...data };

        if (data.fields) {
            transformedData.fields = data.fields.map((field) => field.value);
        } else {
            transformedData.fields = [];
        }

        if (!data.filters) {
            transformedData.filters = [];
        } else {
            // Remove values with a missing field
            transformedData.filters = transformedData.filters.filter((f) => f.comparison && f.field && f.value);
        }

        if (!data.sortCriterias) {
            transformedData.sortCriterias = [];
        } else {
            transformedData.sortCriterias = data.sortCriterias
                .filter((sortCriteria) => {
                    const parsedValue = parseInt(sortCriteria.value);
                    return parsedValue === 0 || parsedValue === 1;
                })
                .map((sortCriteria) => {
                    return {
                        fieldName: sortCriteria.field,
                        order: parseInt(sortCriteria.value)
                    };
                });
        }

        if (data.limit) {
            const limit = parseInt(data.limit);
            if (limit > 0) {
                transformedData.limit = limit;
            } else {
                delete transformedData.limit;
            }
        }

        if (data.type === 'spreadsheet') transformedData.format = 'spreadsheet';

        return transformedData;
    };

    const handleSubmit = (data) => {
        if (output.outputId) {
            return Promise.resolve(handleUpdate(data));
        } else {
            return Promise.resolve(handleCreate(data));
        }
    };

    /** Add an output to a data set.
     * @param {object} data -
     */
    const handleCreate = (data) => {
        const postData = transformPostData(data);
        return Promise.resolve(
            FeedRequest.post(`dataset/${dataSet._id}/output`, postData)
                .then((data) => {
                    FeedHelpers.reloadDataSet(dataSet._id);
                    if (callback) callback(data);
                    setShowDialog(false);
                    return data;
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('editor.outputForm.outputAddFailed', 'feed-management'));
                    throw new Error(error);
                })
        );
    };

    /** Edit an output of a data set.
     * @param {object} data -
     */
    const handleUpdate = (data) => {
        const postData = transformPostData(data);
        return Promise.resolve(
            FeedRequest.patch(`dataset/${dataSet._id}/output/${output.outputId}`, postData)
                .then((data) => {
                    FeedHelpers.reloadDataSet(dataSet._id);
                    if (callback) callback(data);
                    setShowDialog(false);
                    return data;
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('editor.outputForm.outputUpdateFailed', 'feed-management'));
                    throw new Error(error);
                })
        );
    };

    /** Add the open dialog action to the child of this component
     * @param {child} data -
     */
    const childrenWithProps = (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        const props = {
            onClick: () => {
                setShowDialog(true);
            }
        };
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        }
        return child;
    };

    return (
        <React.Fragment>
            {childrenWithProps(children)}
            {showDialog && (
                <div className="feed-management-output-form">
                    {outputColumns && (
                        <FormFlow
                            onSubmit={handleSubmit}
                            data={formData}
                            setup={formSetup}
                            onSubmitComplete={() => {}}
                            onCloseDialog={() => setShowDialog(false)}
                            submitButtonLabel={Translation.get('editor.outputForm.save', 'feed-management')}
                            title={title}
                            view="modal"
                            modalOpen
                            saveNotification={
                                output && output.outputId
                                    ? Translation.get('editor.outputForm.updated', 'feed-management')
                                    : Translation.get('editor.outputForm.added', 'feed-management')
                            }
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default OutputForm;
