import React from 'react';
import Chip from 'components/ui-components-v2/Chip';
import OutputCardBodyLabel from './output-card-body-label';
import '../styles/output-card-body-chips.scss';

/**
 * Display chips for arrays in an outputcard
 * @param {*} param0
 * @returns
 */
const OutputCardBodyChips = ({ label, data }) => {
    let value = [];

    if (Array.isArray(data) && data.length > 0) {
        value = data.map((item, index) => {
            const label = item.field ? item.field : item;
            if (typeof label !== 'string') return null;

            return <Chip key={label + index + index} label={label} size="small" className="feed-management-output-card-body-chips__chip" />;
        });
    } else {
        return null;
    }

    return (
        <React.Fragment>
            <OutputCardBodyLabel label={label} />
            <div className="feed-management-output-card-body-chips">{value}</div>
        </React.Fragment>
    );
};

export default OutputCardBodyChips;
