import React, { useEffect, useState } from 'react';
import Accordion from 'components/ui-components-v2/Accordion';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from 'components/ui-components/Dialog';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import ItemPreviewEntry from './entry';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import '../styles/dialog.scss';

// This is the dialog that shows the details of a record
const ItemPreviewDialog = ({ item, displayTypes, onClose, showDialog = false }) => {
    const [parsedItem, setParsedItem] = useState(FeedHelpers.getParsedItem(item));

    useEffect(() => {
        setParsedItem(FeedHelpers.getParsedItem(item));
    }, [item]);

    return showDialog ? (
        <Dialog title="Feed item" onClose={() => onClose(false)} open={true}>
            <div className="feed-management-item-preview-dialog">
                {parsedItem &&
                    parsedItem.data &&
                    Object.keys(parsedItem.data).map((key) => (
                        <ItemPreviewEntry key={key} entryKey={key} entryValue={parsedItem.data[key]} displayTypes={displayTypes} />
                    ))}

                <React.Fragment>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <b>Metadata</b>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="feed-management-item-preview-dialog__metadata">
                                {parsedItem &&
                                    Object.keys(parsedItem)
                                        .filter((key) => key !== 'data')
                                        .map((key) => <ItemPreviewEntry key={key} entryKey={key} entryValue={parsedItem[key]} displayTypes={displayTypes} />)}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </React.Fragment>
            </div>
        </Dialog>
    ) : null;
};

export default ItemPreviewDialog;
