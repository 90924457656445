import React from 'react';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import OutputCardBodyChips from './output-card-body-chips';
import OutputCardBodyDownload from './output-card-body-download';
import OutputCardBodyCloud from './output-card-body-cloud';
import OutputCardBodySpreadsheet from './output-card-body-spreadsheet';
import OutputCardBodyLabel from './output-card-body-label';
import '../styles/output-card-body.scss';
import Translation from 'components/data/Translation';

/**
 * Display the body / content of an output card.
 * @param {*} param0
 * @returns
 */
const OutputCardBody = ({ output = {}, dataSet = {} }) => {
    /**
     * Render the info of an output depending on the uotput type.
     * @returns
     */
    const renderInfo = () => {
        if (output.type === 'cloud') {
            return <OutputCardBodyCloud output={output} />;
        } else if (output.type === 'download') {
            return <OutputCardBodyDownload output={output} dataSet={dataSet} />;
        } else if (output.type === 'spreadsheet') {
            return <OutputCardBodySpreadsheet output={output} />;
        }
    };

    return (
        <div className="feed-management-output-card-body">
            <OutputCardBodyLabel label={Translation.get('outputs.outputBodies.general', 'feed-management')} />
            <div className="feed-management-output-card-body__value">{FeedHelpers.outputTypes[output.type]}</div>
            {output.type !== 'spreadsheet' && (
                <React.Fragment>
                    <OutputCardBodyLabel label={Translation.get('outputs.outputBodies.format', 'feed-management')} />
                    <div className="feed-management-output-card-body__value">{output.format.toUpperCase()}</div>
                </React.Fragment>
            )}
            <OutputCardBodyChips label={Translation.get('outputs.outputBodyChipColumns', 'feed-management')} data={output.fields} />
            {output.filters && output.filters.length > 0 && (
                <OutputCardBodyChips label={Translation.get('outputs.outputBodyChipFilters', 'feed-management')} data={output.filters} />
            )}
            {output.sortCriterias && output.sortCriterias.length > 0 && (
                <OutputCardBodyChips
                    label={Translation.get('labels.sorting', 'common')}
                    data={output.sortCriterias.map((c) => {
                        return {
                            field: `${c.fieldName} - ${c.order === 1 ? Translation.get('labels.asc', 'common') : Translation.get('labels.desc', 'common')}`
                        };
                    })}
                />
            )}
            {output.limit && output.limit > 0 && (
                <React.Fragment>
                    <OutputCardBodyLabel label={Translation.get('labels.limit', 'common')} />
                    <div className="feed-management-output-card-body__value">{output.limit}</div>
                </React.Fragment>
            )}

            <div className="feed-management-output-card-body__divider"></div>

            <div className="feed-management-output-card-body__info">{renderInfo()}</div>
        </div>
    );
};

export default OutputCardBody;
