import React from 'react';
import classNames from 'classnames';
import AceEditor from 'react-ace';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import '../styles/value.scss';

// Checks the youtube link and returns the id
const youtube_parser = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
};

// Checks if the youtube url is valid
const isYoutubeUrl = (value) => {
    if (!value || typeof value !== 'string') return false;

    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/; //eslint-disable-line
    const match = value.match(regExp);
    return match && match[7].length === 11 ? true : false;
};

// Shows an embedded youtube video
const YoutubeEmbed = ({ value, markAsOld, markAsNew }) => {
    let newValue;

    if (typeof value === 'object' && value.youtubeUrl) {
        newValue = value.youtubeUrl;
    } else {
        newValue = value;
    }

    const youtubeId = youtube_parser(newValue);

    if (!youtubeId) return <div>Invalid youtube id</div>;

    return (
        <div
            className={classNames({
                'feed-management-item-preview-value__border--old': markAsOld,
                'feed-management-item-preview-value__border--new': markAsNew
            })}>
            <iframe
                className="feed-management-item-preview-value__youtube-embed"
                src={`https://www.youtube.com/embed/${youtubeId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );
};

// Shows a default video
const Video = ({ value, markAsOld, markAsNew }) => {
    return (
        <video
            className={classNames('feed-management-item-preview-value__video', {
                'feed-management-item-preview-value__image--old': markAsOld,
                'feed-management-item-preview-value__image--new': markAsNew
            })}
            src={value}
            controls></video>
    );
};

// Shows an image
const Image = ({ value, markAsOld, markAsNew }) => {
    return (
        <div
            className={classNames('feed-management-item-preview-value__image', {
                'feed-management-item-preview-value__image--old': markAsOld,
                'feed-management-item-preview-value__image--new': markAsNew
            })}>
            <img src={value} />
        </div>
    );
};

/**
 * This renders the value of an entry and checks how it should be shown
 */
const ItemPreviewValue = ({ value, entryKey, displayTypes = [], markAsOld, markAsNew }) => {
    let displayType;

    // Check if the item has a specific displayType (for example, a youtube link or an image) and if so, return that displayType
    displayTypes.forEach((item) => {
        if (item.key === entryKey || item.key.replaceAll('.', '_') === entryKey) {
            displayType = item.displayType;
        }
    });

    // Render items by type
    if (displayType === 'youtube') {
        return <YoutubeEmbed value={value} markAsOld={markAsOld} markAsNew={markAsNew} />;
    }

    if (displayType === 'image' || FeedHelpers.isImage(entryKey, value)) {
        return <Image value={value} markAsOld={markAsOld} markAsNew={markAsNew} />;
    }

    if (value && value.assetGalleryInput) {
        return <Image value={value.url} markAsOld={markAsOld} markAsNew={markAsNew} />;
    }

    if (displayType === 'video') {
        return <Video value={value} markAsOld={markAsOld} markAsNew={markAsNew} />;
    }

    switch (typeof value) {
        case 'string':
        case 'number':
            // Check if it's a url and check if that url is an image
            if (FeedHelpers.isValidUrl(value)) {
                if (value.endsWith('jpg') || value.endsWith('png')) {
                    return <Image value={value} />;
                } else if (value.endsWith('.mp4')) {
                    return (
                        <React.Fragment>
                            <Video value={value} markAsOld={markAsOld} markAsNew={markAsNew} />
                            <a
                                target="_blank"
                                href={value}
                                className={classNames('feed-management-item-preview-value__link', {
                                    'feed-management-item-preview-value--old': markAsOld,
                                    'feed-management-item-preview-value--new': markAsNew
                                })}>
                                {value}
                            </a>
                        </React.Fragment>
                    );
                } else if (isYoutubeUrl(value)) {
                    return <YoutubeEmbed value={value} markAsOld={markAsOld} markAsNew={markAsNew} />;
                } else {
                    return (
                        <a
                            target="_blank"
                            href={value}
                            className={classNames('feed-management-item-preview-value__link', {
                                'feed-management-item-preview-value--old': markAsOld,
                                'feed-management-item-preview-value--new': markAsNew
                            })}>
                            {value}
                        </a>
                    );
                }
            }

            // If the value is 1 or 0 and the key is not 'id', return true or false
            if (entryKey !== 'id' && (value === 1 || value === 0)) {
                return (
                    <div
                        className={classNames({
                            'feed-management-item-preview-value--old': markAsOld,
                            'feed-management-item-preview-value--new': markAsNew
                        })}>
                        {value === 1 ? 'True' : 'False'}
                    </div>
                );
            }

            // Format lastUpdatedAt key
            if (entryKey === '_lastUpdatedAt') {
                return (
                    <div
                        className={classNames({
                            'feed-management-item-preview-value--old': markAsOld,
                            'feed-management-item-preview-value--new': markAsNew
                        })}>
                        {FeedHelpers.formatLatestUpdateDate(value)}
                    </div>
                );
            }

            return (
                <div
                    className={classNames({
                        'feed-management-item-preview-value--old': markAsOld,
                        'feed-management-item-preview-value--new': markAsNew
                    })}>
                    {value}
                </div>
            );

        case 'object':
            return (
                <div
                    className={classNames({
                        'feed-management-item-preview-value__border--old': markAsOld,
                        'feed-management-item-preview-value__border--new': markAsNew
                    })}>
                    <AceEditor
                        mode="json"
                        width={'100%'}
                        theme="github"
                        readOnly
                        wrapEnabled
                        showGutter={false}
                        highlightActiveLine={false}
                        minLines={1}
                        maxLines={10}
                        fontSize={14}
                        value={JSON.stringify(value, null, 2)}
                        name={`key_${entryKey}`}
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{ useWorker: false }}
                    />
                </div>
            );
        case 'boolean':
            return (
                <div
                    className={classNames('feed-management-item-preview-value__boolean', {
                        'feed-management-item-preview-value--old': markAsOld,
                        'feed-management-item-preview-value--new': markAsNew
                    })}>
                    {value ? 'True' : 'False'}
                </div>
            );
        default:
            return null;
    }
};

export default ItemPreviewValue;
