import React, { useState } from 'react';
import ItemPreviewDialog from './dialog';
import '../styles/main.scss';

/**
 * This is the dialog that shows the details of a record.
 * The child provided to the component opens the dialog.
 * @param {*} param0 
 * @returns 
 */
const ItemPreview = ({ item, children, displayTypes }) => {
    const [showDialog, setShowDialog] = useState(false);

    // Add on open dialog action to the child.
    const childrenWithProps = (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        const props = {
            onClick: (e) => {
                setShowDialog(true)
            }
        };
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        }
        return child;
    };

    return (
        <React.Fragment>
            {childrenWithProps(children)}
            {showDialog &&
                <ItemPreviewDialog
                    item={item}
                    displayTypes={displayTypes}
                    onClose={setShowDialog}
                    showDialog={showDialog}
                />
            }
        </React.Fragment>
    )
}

export default ItemPreview;
